/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */

// TODO remove createSampleCustomCluster and the above eslint rules
// after development

import { Alert } from "@mui/material";
import { useSetAtom } from "jotai";
import { ClientOnly } from "remix-utils/client-only";
import BoxCenter from "src/modules/ui/components/BoxCenter";

import { createClusterAtom } from "~/customClusters/state/customClustersState";
import { useSubjectProperty } from "~/subjectProperty/state/useSubjectProperty";

interface DefinedCustomClustersGateDefaultFallbackProps {
  sectionName?: string;
}

export default function DefinedCustomClustersGateDefaultFallback({
  sectionName = "this section",
}: DefinedCustomClustersGateDefaultFallbackProps) {
  const { subjectProperty } = useSubjectProperty();
  const createCluster = useSetAtom(createClusterAtom);

  const createSampleCustomCluster = () => {
    const { latitude, longitude } = subjectProperty;

    const delta = 0.07;

    createCluster({
      points: [
        { lat: latitude, lng: longitude + delta },
        { lat: latitude - delta, lng: longitude + delta },
        { lat: latitude - delta, lng: longitude },
        { lat: latitude, lng: longitude },
      ] as any[],
      filters: [],
    });
  };

  return (
    <ClientOnly>
      {() => (
        <BoxCenter>
          <Alert severity="error">
            At least one custom cluster must be defined before accessing{" "}
            {sectionName}.{" "}
            <a href="#" onClick={createSampleCustomCluster}>
              Create sample custom cluster.
            </a>
          </Alert>
        </BoxCenter>
      )}
    </ClientOnly>
  );
}
